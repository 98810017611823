import React from 'react';
import ItImageTwo from '../images/ItImageTwo';
import LogoReel from '../images/logoreel';

const Clients = ({ text, clientImage }) => {
  return (
    <div className="container-fluid bg-neutralLighter text-left px-0">
      <div className="row m-0 p-0">
        <div className="h-100 col-md-7 p-0">
          <div className=" h-100 border-0 rounded-0 text-white">
            {clientImage}
            <div className="card-img-overlay-orange h-100">
              <div className="row p-4 m-0 justify-content-center h-100">
                <div className="align-self-center p-3 bg-transparent">
                  <p className="card-text display-2 text-center text-white">{text}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-5 h-100 p-5 text-left align-self-center">
          <div className="col-md-9 mx-auto">
            <LogoReel />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Clients;
