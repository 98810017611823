import React from 'react';
import BgVideoTestimonial from '../bgVideoTestimonial';

const Quote = ({ job, name, quoteOne, quoteTwo, video }) => {
  return (
    <div className="container-fluid position-relative p-0">
      <div className="border-0 text-left rounded-0 text-white">
        {video}
        <div className="card-img-overlay ov">
          <div className="row m-0 p-0 justify-content-center">
            <div className="align-items-start">
              <span className="p-5 m-1 bg-themeSecondary" />
              <span className="p-5 m-1 bg-themeSecondary" />
            </div>
            <div className="card col-md-9 p-md-5 p-2 rounded-0 align-self-center text-center text-md-left border-0 bg-transparent">
              <p className="card-text display-4  text-neutralDark">{quoteOne}</p>
              <p className="card-text case-text text-neutralDark">{quoteTwo}</p>
              <small className="text-muted">{name}</small>
              <small className="card-title text-primary text-uppercase font-weight-bold">{job}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quote;
