import React from 'react';

import { Link } from 'gatsby';
import Primis from '../../../static/images/primis.svg';
import Medi from '../../../static/images/medi-onai-logo.svg';
import Revive from '../../../static/images/revive-onai-logo.svg';
import Marcote from '../../../static/images/marcote-onai-logo.svg';

const PrimisLogo = () => (
  <>
    <img alt="primis logo" src={Primis} />
  </>
);
const MediservicesLogo = () => (
  <>
    <img alt="Mediservices Logo" src={Medi} />
  </>
);

const ReviveLogo = () => (
  <>
    <img alt="Revive Logo" src={Revive} />
  </>
);

const MarcoteLogo = () => (
  <>
    <img alt="Marcote" src={Marcote} />
  </>
);

const LogoReel = () => (
  <div className="row text-secondary h-100">
    <div className="col-12 p-1 h-100">
      <h3 className="text-black">A few of our clients</h3>
    </div>
    <div className="col-12 p-1 h-100">
      <Link to="https://mediservices.healthcare">
        <h4>MediServices Health and Care Group</h4>
      </Link>
    </div>
    <div className="col-12 p-1 h-100">
      <Link to="https://revivemydevice.com">
        <h4>Revive My Device LTD</h4>
      </Link>
    </div>
    <div className="col-12 p-1 h-100">
      <Link to="https://micherowholefoods.com">
        <h4>Michero Wholefoods</h4>
      </Link>
    </div>
    <div className="col-12 p-1 h-100">
      <Link to="https://macote.com">
        <h4>Marcote Ltd</h4>
      </Link>
    </div>
    {/* <div className="col-12 p-3 h-100"> */}
    {/*  <h4>Amari Life</h4> */}
    {/* </div> */}
    <div className="col-12 p-1 h-100">
      <Link to="https://cenkos-global.com">
        <h4>Cenkos Ltd</h4>
      </Link>
    </div>
  </div>
);

export default LogoReel;
